<script setup>
  import Pagination from '@/components/Pagination'
  import Rating from '@/views/order/compontent/rating'
  import reviewItem from '@/views/product/details/compontent/review-item'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, defineExpose } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    productId: {
      type: String
    }
  })

  const data = reactive({
    loading: false,
    recordsProductInfo: {}, // 评论信息
    reviewList: [],
    parmsPage: {
      total: 0,
      pageSize: 10,
      currentPage: 1
    },
    currentLevel: '',
    productImage: false // 查看带图的评论
  })

  // 计算占比
  const calculateStarPercentage = () => {
    const totalScores = data.recordsProductInfo.levelCount.reduce((sum, review) => sum + review.count, 0)
    data.recordsProductInfo.levelCount.forEach((item) => {
      item.typeName = `${item.level}颗星`
      item.progress = parseInt((item.count / totalScores) * 100)
    })
    data.recordsProductInfo.levelCount.sort((a, b) => b.level - a.level)
    // console.log(data.recordsProductInfo.levelCount, 'data.recordsProductInfo.levelCount')
  }

  //查询商品评论条数
  const handleCommentCount = (res) => {
    // console.log(res, 'handleCommentCount')
    data.recordsProductInfo = res
    calculateStarPercentage()
    getRecordsList('init')
  }

  //查询商品评论列表
  const getRecordsList = (type) => {
    if (data.loading) return
    data.loading = true
    const param = {
      productId: props.productId,
      level: data.currentLevel,
      productImage: data.productImage,
      size: data.parmsPage.pageSize,
      page: data.parmsPage.currentPage - 1
    }
    // console.log(param, 'param')
    api.product
      .recordsList(param)
      .then((res) => {
        // console.log('查询评论列表', res)
        if (res && res.status && res.data.content && res.data.content.length) {
          data.reviewList = res.data.content
          data.parmsPage.total = res.data.totalElements
          if (type !== 'init') {
            proxy.JLTools.toTop(0, 500)
          }
        }
      })
      .finally(() => {
        data.loading = false
      })
  }

  const getProductImage = () => {
    data.parmsPage.currentPage = 1
    data.parmsPage.total = 0
    data.reviewList = []
    data.productImage = true
    data.currentLevel = ''
    getRecordsList()
  }
  // 切换tab
  const changeType = (level) => {
    // console.log('查看星级')
    data.parmsPage.currentPage = 1
    data.parmsPage.total = 0
    data.reviewList = []
    data.productImage = false
    data.currentLevel = level
    getRecordsList()
  }

  const handCurrenPage = (val) => {
    console.log('翻页')
    data.parmsPage.currentPage = val
    data.reviewList = []
    getRecordsList()
  }

  defineExpose({
    handleCommentCount
  })
</script>

<template>
  <div>
    <div v-if="data.recordsProductInfo.commentRecordCount > 0">
      <div class="wh100 rowSS score-box">
        <div class="columnCS review-section" v-loading="data.loading">
          <template v-if="data.reviewList.length">
            <div v-for="(item, index) in data.reviewList" :key="index" class="review-item">
              <review-item :reviewItem="item" :isLastItem="index === data.reviewList.length - 1"></review-item>
            </div>
            <div class="rowEC mt20 w12" style="justify-content: center;">
              <Pagination
                :config="{ total: data.parmsPage.total, pageSize: data.parmsPage.pageSize, currentPage: data.parmsPage.currentPage }"
                v-if="data.parmsPage.total > 10"
                @currentPage="handCurrenPage"
              ></Pagination>
            </div>
          </template>

          <template v-else>
            <el-empty image="https://obs.pricoo.pk/d7b07b30.png" description="No reviews yet" image-size="400" />
          </template>
          <!-- <div class="score-num">{{ data.recordsProductInfo.commentCountAvg }}</div>
          <Rating :curRating="data.recordsProductInfo.commentCountAvg" />
          <div class="num">{{ data.recordsProductInfo.commentRecordCount }} Ratings</div> -->
        </div>
        <div class="fixed-review-box">
          <div class="customer-reviews-title">Customer Reviews</div>
          <div class="flex_center" style="margin-bottom: 8px">
            <span class="review-count">{{ data.recordsProductInfo.commentCountAvg }}</span>
            <div class="flex_center rating-tag">
              <i class="iconfont wujiaoxing icon-kongxinwujiaoxing icon-shixinwujiaoxing"></i>
              <span class="tag-content" v-if="data.recordsProductInfo.commentCountAvg <= 1">Poor</span>
              <span class="tag-content" v-else-if="data.recordsProductInfo.commentCountAvg > 1 && data.recordsProductInfo.commentCountAvg <= 2">Fair</span>
              <span class="tag-content" v-else-if="data.recordsProductInfo.commentCountAvg > 2 && data.recordsProductInfo.commentCountAvg <= 3">Good</span>
              <span class="tag-content" v-else-if="data.recordsProductInfo.commentCountAvg > 3 && data.recordsProductInfo.commentCountAvg <= 4">Very Good</span>
              <span class="tag-content" v-else>Excellent</span>
            </div>
          </div>
          <div class="flex_center" style="margin-bottom: 24px;">
            <Rating :curRating="data.recordsProductInfo.commentCountAvg" />
            <span class="reviews">Based on {{ data.recordsProductInfo.commentRecordCount }} reviews</span>
          </div>

          <div class="rowSC" v-for="(item, index) in data.recordsProductInfo.levelCount" :key="index" style="margin-bottom: 12px;">
            <span class="mr5 rating-level">{{ item.level }}</span>
            <Rating :curRating="item.level" />
            <!-- <i class="iconfont wujiaoxing icon-shixinwujiaoxing"></i> -->
            <!-- <Rating :curRating="item.level" /> -->
            <!-- :color="customColor" -->
            <div class="progress-box">
              <el-progress :percentage="item.progress" :stroke-width="10">
                <div class="ml10 rating-level">{{ item.progress }}%</div>
              </el-progress>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="rowSC rating-summary-box">
        <div class="rowSC phone-image-box" @click="getProductImage">
          <JLImg class="phone-image" src="d94eb73a.png"></JLImg>
          <div>
            Photo(<span>{{ data.recordsProductInfo.imgCount }}</span
            >)
          </div>
        </div>
        <div class="rowSC">
          <div class="rowSC summary-item" v-for="(item, index) in data.recordsProductInfo.levelCount" :key="index" @click="changeType(item.level)">
            <i class="iconfont wujiaoxing icon-shixinwujiaoxing"></i>
            <span>{{ item.level }}</span>
          </div>
        </div>
      </div> -->

      <!-- 评论列表 -->
      <!-- <div class="review-list-box" v-loading="data.loading">
        <div class="line"></div>
        <template v-if="data.reviewList.length">
          <div v-for="(item, index) in data.reviewList" :key="index" class="review-item">
            <review-item :reviewItem="item"></review-item>
          </div>
          <div class="rowEC mt20">
            <Pagination
              :config="{ total: data.parmsPage.total, pageSize: data.parmsPage.pageSize, currentPage: data.parmsPage.currentPage }"
              v-if="data.parmsPage.total > 10"
              @currentPage="handCurrenPage"
            ></Pagination>
          </div>
        </template>

        <template v-else>
          <el-empty image="https://obs.pricoo.pk/d7b07b30.png" description="No reviews yet" :image-size="400" />
        </template>
      </div> -->
    </div>
    <div v-else class="rowCC w100">
      <el-empty image="https://obs.pricoo.pk/d7b07b30.png" description="No reviews yet" :image-size="400" />
    </div>
  </div>
</template>

<style lang="less" scoped>
  .progress-box {
    width: 100%;
    margin-left: 5px;
    :deep(.el-progress-bar__outer) {
      background-color: #F0F1F1;
    }
    :deep(.el-progress-bar__inner) {
      background-color: #F7BA2A;
    }
  }
  .score-box {
    color: #222;
    margin-top: 10px;
    .review-section {
      padding: 16px 30px 16px 16px;
      width: 70%;
    }
    .review-item {
      width: 100%;
    }
    .score-num {
      margin-left: 2px;
      font-size: 26px;
      font-weight: 600;
    }
    .num {
      margin-left: 3px;
      margin-top: 5px;
      font-size: 14px;
    }
    .fixed-review-box {
      width: 27%;
      .rating-tag {
        margin-left: 12px;
        background-color: #F7BA2A;
        padding: 0 12px 0 4px;
        color: var(--mainTextColor);
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: -10px;
          width: 0;
          height: 0;
          border-top: 20px solid #F7BA2A;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
        }
        .icon-shixinwujiaoxing {
          color: var(--mainTextColor) !important;
        }
        .tag-content {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .review-count {
        color: var(--mainBgColor);
        font-size: 24px;
        line-height: 28px;
        font-weight: 400;
      }
      .reviews {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        margin-left: 6px;
        color: #606266;
      }
      .rating-level {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--mainBgColor);
      }
      .customer-reviews-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 24px;
        color: var(--mainBgColor);
      }
    }
  }

  .rating-summary-box {
    margin-top: 18px;
    color: #222;
    font-size: 14px;
    font-weight: 500;
    .phone-image-box {
      padding: 0 8px;
      border-radius: 4px;
      border: 1px solid #ccc;
      cursor: pointer;
      .phone-image {
        width: 38px;
        height: 38px;
        margin-right: 4px;
      }
    }
    .summary-item {
      padding: 4px 12px 4px 3px;
      margin-left: 25px;
      border-radius: 6px;
      background: #f9f9f9;
      cursor: pointer;
      &:hover {
        color: #fff;
        background-color: #999;
      }
    }
  }

  .review-list-box {
    min-height: 400px;
    .line {
      width: 80%;
      height: 1px;
      margin: 12px 0;
      background: #ccc;
    }
  }

  .infinite-list {
    width: 100%;
    height: 800px;
    padding: 0;
    margin: 0;
    list-style: none;
  }
</style>
