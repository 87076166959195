<script setup>
  import ImageView from './ImageView'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, defineExpose, watch, provide } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    productData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })

  const data = reactive({
    imgList: [],
    coverImg: ''
  })

  // 获取商品展示图片
  const getImgsList = (imgs) => {
    if (JLTools.isNull(imgs)) return
    let imgList = []
    imgs.split(',').forEach((item) => {
      let data = {
        path: item
      }
      imgList.push(data)
    })
    data.imgList = imgList
  }

  // 分享
  const handleShare = (imgs) => {
    const currentURL = window.location.href
    const tempInput = document.createElement('input')
    document.body.appendChild(tempInput)
    tempInput.value = currentURL
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    // console.log('复制成功')
    proxy.$toast('Share link copied.', { type: 'succeed' })
  }

  defineExpose({
    getImgsList
  })
</script>

<template>
  <div>
    <div class="pro-main-left">
      <div class="img-list-box">
        <ImageView v-if="data.imgList.length > 0" :imgList="data.imgList" :curImage="data.coverImg" ref="imageView"></ImageView>
      </div>
      <div class="fenxiang rowSC">
        {{ $t('Share product') }}:
        <div class="fenxiang-item rowCC" @click="handleShare">
          <img class="img" src="https://obs.pricoo.pk/b0cc8753.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .pro-main-left {
    width: 100%;
    padding-top: 16px;
    .img-list-box {
      min-width: 348px;
      min-height: 348px;
    }
    .fenxiang {
      width: 100%;
      padding: 15px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #606266;
      .fenxiang-item {
        cursor: pointer;
        margin-left: 12px;
        position: relative;
        .img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
</style>
