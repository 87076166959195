<!-- 推荐商品列表 -->
<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import Rating from '@/views/order/compontent/rating'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    productId: {
      type: String
    },
    productInfo: {
      type: Object
    }
  })

  const data = reactive({
    pickProductList: []
  })

  const getPickProduct = () => {
    if (userStore.appHomeName === 'PRICOO') {
      return getPickProductPricoo()
    }
    getPickProductHM()
  }

  //获取推荐商品
  const getPickProductHM = () => {
    const parms = {
      'executed.equals': 'true',
      size: 4,
      page: 0,
      sort: 'saleCount,desc',
      'firstCategoryId.equals': props.productInfo.firstCategory.id,
      'productType.equals': 'GENERAL',
      'id.notEquals': props.productId
    }
    api.product.getProducts(parms).then((res) => {
      data.pickProductList = res.data
      console.log('商品详情', data.pickProductList)
    })
  }

  const getPickProductPricoo = () => {
    const parms = {
      'executed.equals': 'true',
      size: 4,
      page: 0,
      sort: 'saleCount,desc',
      'productType.equals': 'GENERAL',
      'id.notEquals': props.productId,
      include: ['{id,name,amount,lineAmount,supplier,redTag,coverImg,productImg,buyingPointInfo,productType,specs{stockCount},specialOffer}']
    }
    api.product.getProductsList2(parms).then((res) => {
      data.pickProductList = res.data
      console.log('商品详情', data.pickProductList)
    })
  }

  // 跳转商品详情页
  const pushToYou = (id) => {
    const { href } = proxy.$router.resolve({
      name: 'productMain',
      query: { id: id }
    })
    window.open(href, '_blank')
  }

  onMounted(() => {
    // getPickProduct()
  })

  defineExpose({
    getPickProduct
  })
</script>

<template>
  <div class="popular-products-box">
    <div class="pro-left-title">{{ $t('Popular Products') }}</div>
    <div class="pro-item" v-for="(item, index) of data.pickProductList" :key="index" @click="pushToYou(item.id)">
      <div class="pro-img"><JLImg :src="item.coverImg" /></div>
      <div class="content-section">
        <div class="pro_pic_Y" v-if="item.percentage">{{ item.percentage }}</div>
        <div class="pro-name text_hide2">{{ item.name }}</div>
        <div class="pro-pic">{{ JLTools.forPrice(item.amount) }}</div>
        <div class="pro-pic-x" v-if="item.lineAmount && item.lineAmount > item.amount">{{ JLTools.forPrice(item.lineAmount) }}</div>
        <div class="pro_maidian" v-if="item.commentCount > 0">
          <Rating :curRating="item.avgLevel" />
          <div class="score-num">({{ item.commentCount }})</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .popular-products-box {
    .pro-left-title {
      float: left;
      width: 100%;
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 24px;
      color: var(--mainBgColor);
      font-weight: 500;
    }
    .pro-item {
      float: left;
      width: 100%;
      margin-bottom: 24px;
      // border-top: 1px solid #eee;
      // padding: 10px;
      .content-section {
        float: left;
        padding: 16px 12px;
      }
      .pro_pic_Y {
        background-color: #eef6fb;
        border: 1px solid #cae3f4;
        padding: 6px 12px;
        margin-bottom: 8px;
        width: 80px;
        height: 28px;
        align-items: center;
        justify-content: center;
        display: flex;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #53a4db;
        border-radius: 4px;
      }
      &:hover {
        cursor: pointer;
        box-shadow: 0 0 12px 0 rgba(83, 164, 219, 0.12);
        transition: all 0.3s;
        border-radius: 4px;
      }
      .pro-img {
        float: left;
        // width: 160px;
        // height: 160px;
        .el-image {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .pro-name {
        float: left;
        width: 100%;
        line-height: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      .pro-pic {
        float: left;
        width: 100%;
        line-height: 28px;
        margin-top: 8px;
        font-size: 18px;
        font-weight: 400;
        color: var(--Zcolor);
      }
      .pro-pic-x {
        float: left;
        width: 100%;
        line-height: 12px;
        color: #909399;
        font-weight: 400;
        font-size: 12px;
        text-decoration: line-through;
      }
      .pro_maidian {
        float: left;
        width: 100%;
        color: #222;
        margin-top: 8px;
        user-select: text;
        display: flex;
        align-items: center;
        :deep(.wujiaoxing) {
          margin-right: 0px;
        }
        .score-num {
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          color: #606266;
        }
      }
    }
  }
</style>
